<template>
  <AppPage hideBottomBar>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.replace('/pet-list')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </template>
    <div class="page-container">
      <div>
        <v-img
          center
          contain
          max-height="250"
          src="@/components/svg/interested-in.svg"
        ></v-img>

        <p style="font-weight:bold" class="mt-10">
          {{ $vuetify.lang.t("$vuetify.pet_request_submitted") }}
        </p>
        <p class="subtitle-1">
          {{ $vuetify.lang.t("$vuetify.pet_request_description") }}
        </p>
      </div>

      <p class="button">
        <app-button
          color="primary"
          @click="$router.replace('/profile')"
          :disabled="disabled"
          >{{ $vuetify.lang.t("$vuetify.my_profile") }}</app-button
        >
      </p>
    </div>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const petModule = createNamespacedHelpers("pet");

export default {
  name: "pet-request-submitted",
  data() {
    return {
      disabled: true,
    };
  },
  computed: {},
  methods: {
    ...popupModule.mapMutations(["SET_SHEET"]),
    ...petModule.mapMutations(["SET_ADVERTISEMENT"]),
    openAd() {
      this.SET_SHEET({
        showSheet: true,
        sheetComponent: "advertisement",
        sheetOptions: {
          fullscreen: true,
        },
      });
    },
  },
  mounted() {
    setTimeout(() => (this.disabled = false), 3000);
  },
  created() {
    this.openAd();
  },
  beforeDestroy() {
    this.SET_ADVERTISEMENT(null);
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;

  > div {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  p.button {
    width: 100%;
    padding-bottom: 2rem;
  }
}
</style>
